import {
  Box,
  Button,
  Card,
  CardBody,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { MdCheck, MdClose } from 'react-icons/md'
import {
  useCheckMovimenti,
  useDownloadMovimentiConto,
  useMovimentiConto,
  useUpdateDataPagamento,
  useUpdateRiportataEC,
} from '../../../hooks/movimenti'
import { useQsFilters } from '../../../hooks/filters'
import {
  useContoBancario,
  useContoBancarioTotali,
} from '../../../hooks/conti-bancari'
import { useCanGestioneConti } from '../../../permissions'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import BreadCrumb from '../../../components/BreadCrumb'
import SubHeaderBlockPage from '../../../components/SubHeaderBlockPage'
import LangLinkWithStyle from '../../../components/LangLinkWithStyle'
import { OPTIONS_CAUSALI } from '../../../consts'
import createStringLink, { numberFormatter } from '../../../utils'
import Paginator from '../../../components/Paginator'
import { useProgetto } from '../../../hooks/progetti'
import { Select } from 'chakra-react-select'
import SelectAutoCompleteFilter from '../../../components/SelectAutocompleFilter'
import ReactDatePicker from 'react-datepicker'
import { orderBy } from 'lodash'
import ModalCheckMovimenti from './ModalCheckMovimenti'
import { useLastSaldoCertificato } from '../../../hooks/saldi-certificati'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'progressivo',
  causale: params.get('causale') ?? '',
  data_competenza_da: params.get('data_competenza_da') ?? '',
  data_competenza_a: params.get('data_competenza_a') ?? '',
  budget: params.get('budget') ?? '',
  riportata_e_c: params.get('riportata_e_c') ?? '',
  controllato: params.get('controllato') ?? '',
  mostra_movimenti_pre_saldo_certificato:
    params.get('mostra_movimenti_pre_saldo_certificato') ?? 'false',
})

export default function MovimentiContoProgetto() {
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)
  const { t, i18n } = useTranslation()
  const { id, contoId } = useParams()

  const filtersMovements = useMemo(() => {
    return {
      ...filters,
      conto_bancario: contoId,
      progetto: id,
      bozza: false,
    }
  }, [filters, id, contoId])

  const {
    data: movimenti,
    isLoading,
    refetch,
  } = useMovimentiConto(contoId, filtersMovements)
  const { data: progetto } = useProgetto(Number(id!))
  const { data: contoBancario } = useContoBancario(Number(contoId!))

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('accounts'),
        link: `/projects/${id}/accounts`,
      },
      {
        label: contoBancario?.nome_banca ?? '',
        link: `/projects/${id}/accounts`,
      },
      {
        label: t('movements'),
      },
    ]
  }, [t, progetto?.codice, id, contoBancario?.nome_banca])

  const userCanGestioneConti = useCanGestioneConti()

  const optionsCausali = useMemo(() => {
    return orderBy(OPTIONS_CAUSALI, ['label'], ['asc'])
  }, [])

  const [showFilters, setShowFilters] = useState(true)

  const [movimentiForCheck, setMovimentiForCheck] = useState<number[]>([])

  const {
    isOpen: isOpenCheckMovimenti,
    onOpen: onOpenCheckMovimenti,
    onClose: onCloseCheckMovimenti,
  } = useDisclosure()

  const {
    isOpen: isOpenDataPagamento,
    onOpen: onOpenDataPagamento,
    onClose: onCloseDataPagamento,
  } = useDisclosure()

  const booleanOptions = useMemo(() => {
    return [
      {
        value: 'true',
        label: t('yes'),
      },
      {
        value: 'false',
        label: t('no'),
      },
    ]
  }, [t])

  const checkMovimenti = useCheckMovimenti()
  const updateRiportataEC = useUpdateRiportataEC()
  const updateDataPagamento = useUpdateDataPagamento()

  const navigate = useNavigate()

  const [movForDataPagamento, setMovForDataPagamento] = useState<number | null>(
    null
  )

  const downloadMovimentiConto = useDownloadMovimentiConto(
    contoBancario?.id,
    filtersMovements
  )

  const [dataPagamento, setDataPagamento] = useState<string | null>(null)

  const filtersMovementsTotali = useMemo(() => {
    return {
      progetto: Number(id!),
    }
  }, [id])

  const { data: totali } = useContoBancarioTotali(
    Number(contoId!),
    filtersMovementsTotali
  )

  const { data: saldo } = useLastSaldoCertificato(Number(contoId!), Number(id!))

  const movimentiWithSaldi = useMemo(() => {
    if (!saldo) {
      return movimenti?.results
    }
    // saldi as movimenti
    const saldiAsMovimenti = {
      ...saldo,
      id: saldo.id,
      progressivo: undefined,
      progetto: saldo.progetto,
      progetto_data: saldo.progetto_data,
      numero_documento: '',
      valuta_controllo: contoBancario?.valuta_data?.codice,
      data_documento: undefined,
      data_competenza: saldo.data_saldo,
      causale: '',
      descrizione: '',
      budget: '',
      importo_controllo_versamenti: '',
      somma_parziale: saldo.saldo,
      somma_parziale_controllati: undefined,
      data_pagamento: null,
      riportata_e_c: false,
      is_saldo: true,
    } as any

    
    const mov = movimenti ? [...movimenti.results, saldiAsMovimenti] : [saldiAsMovimenti]
    // order by data_competenza
    return orderBy(mov, ['data_competenza'], ['asc'])
  }, [saldo, movimenti])

  const [isCheckingMovimenti, setIsCheckingMovimenti] = useState(false)

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          sticky
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanGestioneConti && movimentiForCheck.length > 0 && (
                <Button
                  size={'sm'}
                  border={'1px solid'}
                  borderColor={'brandBlue'}
                  color={'brandBlue'}
                  bg={'card'}
                  _hover={{
                    bg: 'brandBlue',
                    color: 'white',
                  }}
                  me={2}
                  onClick={() => {
                    onOpenCheckMovimenti()
                  }}
                >
                  {t('control_movements')}
                </Button>
              )}
              {userCanGestioneConti && (
                <Button
                  size={'sm'}
                  me={2}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        path: `/certified-balances/create?conto_bancario=${contoId}&progetto=${id}`,
                        lang: i18n.language,
                      })
                    )
                  }}
                >
                  {t('create_certified_balance')}
                </Button>
              )}
              <Button
                size={'sm'}
                bg={'brandLight'}
                color={'brand'}
                me={2}
                _hover={{
                  bg: 'brandSecondary',
                  color: 'white',
                }}
                onClick={() => {
                  setShowFilters(!showFilters)
                }}
              >
                {showFilters ? t('hide_filters') : t('show_filters')}
              </Button>
            </Box>
          }
        />
        {showFilters && (
          <Card
            px={2}
            py={0}
            // py={2}
            mt={1}
            zIndex={99}
            mb={2}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <CardBody py={3}>
              <Grid templateColumns={'repeat(6, 1fr)'} gap={2}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('causale')}</FormLabel>
                    <Select
                      options={optionsCausali ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          causale: values?.value ?? '',
                          page: 1,
                        })
                      }}
                      value={
                        optionsCausali?.find(
                          (v) => v.value === filters.causale
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>

                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('budget')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.budget}
                      url={'/api/budgets/options/'}
                      keyQuery={'budgets-options'}
                      params={{
                        progetto: id,
                      }}
                      isMulti={false}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            budget: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            budget: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('date_competence_from')}
                    </FormLabel>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      onChange={(date) => {
                        if (date) {
                          setFilters({
                            ...filters,
                            data_competenza_da:
                              dayjs(date).format('YYYY-MM-DD'),
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            data_competenza_da: '',
                            page: 1,
                          })
                        }
                      }}
                      dateFormat={'dd/MM/yyyy'}
                      clearButtonClassName={'clear-button'}
                      selected={
                        filters.data_competenza_da
                          ? dayjs(filters.data_competenza_da).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('date_competence_to')}
                    </FormLabel>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      onChange={(date) => {
                        if (date) {
                          setFilters({
                            ...filters,
                            data_competenza_a: dayjs(date).format('YYYY-MM-DD'),
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            data_competenza_a: '',
                            page: 1,
                          })
                        }
                      }}
                      dateFormat={'dd/MM/yyyy'}
                      clearButtonClassName={'clear-button'}
                      selected={
                        filters.data_competenza_a
                          ? dayjs(filters.data_competenza_a).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('Riportata_e_c')}</FormLabel>
                    <Select
                      options={booleanOptions ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          riportata_e_c: values?.value ?? '',
                          page: 1,
                        })
                      }}
                      value={
                        booleanOptions?.find(
                          (v) => v.value === filters.riportata_e_c
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('checked')}</FormLabel>
                    <Select
                      options={booleanOptions ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          controllato: values?.value ?? '',
                          page: 1,
                        })
                      }}
                      value={
                        booleanOptions?.find(
                          (v) => v.value === filters.controllato
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>

                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('show_all_movements')}
                    </FormLabel>
                    <Select
                      options={booleanOptions ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          mostra_movimenti_pre_saldo_certificato:
                            values?.value ?? '',
                          page: 1,
                        })
                      }}
                      value={
                        booleanOptions?.find(
                          (v) =>
                            v.value ===
                            filters.mostra_movimenti_pre_saldo_certificato
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            uiFilters={uiFilters}
            downloadWithParams={downloadMovimentiConto}
            data={movimenti}
            filters={filters}
            options={[{ value: 'progressivo', label: t('progressive') }]}
          />
        </Flex>
        {isLoading ? (
          <Flex align={'center'} justify="center">
            <Spinner color="orange" />
          </Flex>
        ) : (
          <>
            <Table mt={2} variant={'pinc'} bg={'white'}>
              <Thead>
                <Tr>
                  <Th>{t('total_movements')}</Th>
                  <Th>{t('total_movements_checked')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    {numberFormatter.format(totali?.totale_movimenti ?? 0)}{' '}
                    {contoBancario?.valuta_data?.codice}
                  </Td>
                  <Td>
                    {numberFormatter.format(
                      totali?.totale_movimenti_controllati ?? 0
                    )}{' '}
                    {contoBancario?.valuta_data?.codice}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
            <Box
              mt={3}
              height={
                showFilters
                  ? 'calc(100vh - 400px - 90px)'
                  : 'calc(100vh - 240px - 90px)'
              }
              overflowY={'auto'}
              boxShadow={
                '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
              }
            >
              <Table variant={'pinc'} bg={'white'}>
                <Thead position={'sticky'} top={0}>
                  <Tr>
                    {userCanGestioneConti && (
                      <Th>
                        <Checkbox
                          width={5}
                          height={5}
                          size={'md'}
                          colorScheme={'orange'}
                          borderColor={'brandLight'}
                          isChecked={
                            movimentiForCheck.length === movimenti?.count
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setMovimentiForCheck(
                                movimenti?.results.map((m) => m.id) ?? []
                              )
                            } else {
                              setMovimentiForCheck([])
                            }
                          }}
                        />
                      </Th>
                    )}
                    <Th>{t('prog')}</Th>
                    <Th>{t('document_number')}</Th>
                    <Th>{t('date_document')}</Th>
                    <Th>{t('date_competence')}</Th>
                    <Th>{t('causale')}</Th>
                    <Th>{t('description')}</Th>
                    <Th>{t('budget')}</Th>
                    <Th>{t('accounting_amount')}</Th>
                    <Th>{t('somma_parziale')}</Th>
                    <Th>{t('somma_parziale_controllati')}</Th>
                    {userCanGestioneConti && (
                      <>
                        <Th>{t('checked')}</Th>
                        <Th>Rip. e c.</Th>
                      </>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {/* {saldo && filters.page === 1 && (
                    <Tr>
                      <Td colSpan={4} bg={'brandLight'} fontWeight={700}>
                        {t('last_certified_balance')}
                      </Td>
                      <Td colSpan={5} bg={'brandLight'} fontWeight={700}>
                        {dayjs(saldo.data_saldo).format('DD/MM/YYYY')}
                      </Td>
                      <Td
                        textAlign={'right'}
                        bg={'brandLight'}
                        fontWeight={700}
                      >
                        {numberFormatter.format(saldo.saldo)}{' '}
                        {contoBancario?.valuta_data?.codice}
                      </Td>
                      <Td bg={'brandLight'} colSpan={3}></Td>
                    </Tr>
                  )} */}
                  {movimentiWithSaldi?.map((movimento) => (
                    <Tr padding={2} key={movimento.id}>
                      {userCanGestioneConti && (
                        <Td bg={movimento.is_saldo ? 'brandLight' : 'inherit'}>
                          {!movimento.is_saldo && (
                            <Checkbox
                              width={5}
                              height={5}
                              size={'md'}
                              colorScheme={'orange'}
                              borderColor={'brandLight'}
                              isChecked={movimentiForCheck.includes(
                                movimento.id
                              )}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setMovimentiForCheck([
                                    ...movimentiForCheck,
                                    movimento.id,
                                  ])
                                } else {
                                  setMovimentiForCheck(
                                    movimentiForCheck.filter(
                                      (id) => id !== movimento.id
                                    )
                                  )
                                }
                              }}
                            />
                          )}
                        </Td>
                      )}
                      <Td bg={movimento.is_saldo ? 'brandLight' : 'inherit'}>
                        <Box display={'flex'} alignItems={'center'}>
                          <LangLinkWithStyle
                            to={`/projects/${movimento.progetto}/movements/${movimento.id}`}
                          >
                            {movimento.progressivo}
                          </LangLinkWithStyle>
                        </Box>
                      </Td>
                      <Td bg={movimento.is_saldo ? 'brandLight' : 'inherit'}>
                        {movimento.numero_documento}
                      </Td>
                      <Td bg={movimento.is_saldo ? 'brandLight' : 'inherit'}>
                        {movimento.data_documento
                          ? dayjs(movimento.data_documento).format('DD/MM/YYYY')
                          : ''}
                      </Td>
                      <Td bg={movimento.is_saldo ? 'brandLight' : 'inherit'}>
                        {dayjs(movimento.data_competenza).format('DD/MM/YYYY')}
                      </Td>
                      <Td bg={movimento.is_saldo ? 'brandLight' : 'inherit'}>
                        {
                          OPTIONS_CAUSALI.find(
                            (causale) => causale.value === movimento.causale
                          )?.label
                        }
                      </Td>
                      <Td
                        bg={movimento.is_saldo ? 'brandLight' : 'inherit'}
                        className="text-wrap"
                      >
                        {movimento.descrizione}
                        {movimento.is_saldo && t('last_certified_balance')}
                      </Td>
                      <Td bg={movimento.is_saldo ? 'brandLight' : 'inherit'}>
                        {movimento.budget_data?.codice}{' '}
                        {movimento.budget_data?.codice_paese
                          ? `- ${movimento.budget_data?.codice_paese}`
                          : ''}
                        {movimento.budget_data?.codice_piano_conti
                          ? ` - ${movimento.budget_data?.codice_piano_conti}`
                          : ''}
                      </Td>
                      <Td
                        textAlign={'right'}
                        bg={movimento.is_saldo ? 'brandLight' : 'inherit'}
                      >
                        {!movimento.is_saldo &&numberFormatter.format(
                          movimento.importo_controllo_versamenti ?? 0
                        )}
                        <br />
                        {!movimento.is_saldo && movimento.valuta_controllo}
                      </Td>
                      <Td
                        textAlign={'right'}
                        bg={movimento.is_saldo ? 'brandLight' : 'inherit'}
                      >
                        {numberFormatter.format(movimento.somma_parziale ?? 0)}{' '}
                        <br />
                        {movimento.valuta_controllo}
                      </Td>
                      <Td
                        textAlign={'right'}
                        bg={movimento.is_saldo ? 'brandLight' : 'inherit'}
                      >
                        {!movimento.is_saldo && numberFormatter.format(
                          movimento.somma_parziale_controllati ?? 0
                        )}{' '}
                        <br />
                        {!movimento.is_saldo && movimento.valuta_controllo}
                      </Td>
                      {userCanGestioneConti && (
                        <>
                          <Td
                            whiteSpace={'nowrap'}
                            bg={movimento.is_saldo ? 'brandLight' : 'inherit'}
                          >
                            {movimento.data_pagamento ? (
                              <Box
                                cursor={'pointer'}
                                onClick={() => {
                                  setMovForDataPagamento(movimento.id)
                                  setDataPagamento(movimento.data_pagamento)
                                  onOpenDataPagamento()
                                }}
                              >
                                {dayjs(movimento.data_pagamento).format(
                                  'DD/MM/YYYY'
                                )}
                              </Box>
                            ) : (
                              <MdClose
                                cursor={'pointer'}
                                onClick={() => {
                                  setMovForDataPagamento(movimento.id)
                                  onOpenDataPagamento()
                                }}
                                color={'red'}
                              />
                            )}
                          </Td>
                          <Td
                            bg={movimento.is_saldo ? 'brandLight' : 'inherit'}
                            cursor={'pointer'}
                            whiteSpace={'nowrap'}
                            onClick={() => {
                              updateRiportataEC
                                .mutateAsync({
                                  id: movimento.id,
                                  riportata_e_c: !movimento.riportata_e_c,
                                })
                                .then(() => {
                                  refetch()
                                })
                            }}
                          >
                            {movimento.riportata_e_c ? (
                              <MdCheck color={'green'} />
                            ) : (
                              <MdClose color={'red'} />
                            )}
                          </Td>
                        </>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            <Box
              width={'100%'}
              mt={3}
              display={'flex'}
              justifyContent={'flex-end'}
            >
              <Paginator
                count={movimenti!.count}
                currentPage={uiFilters.page}
                goToPage={(page: number) => setFilters({ page })}
              />
            </Box>
          </>
        )}
      </Flex>
      <ModalCheckMovimenti
        isOpen={isOpenCheckMovimenti}
        onClose={onCloseCheckMovimenti}
        isCheckingMovimenti={isCheckingMovimenti}
        onToggle={onOpenCheckMovimenti}
        conto={contoBancario}
        progetto={progetto}
        onConfirm={async ({ data_pagamento, riportata_e_c }) => {
          await checkMovimenti
            .mutateAsync({
              movimenti: movimentiForCheck,
              data_pagamento,
              riportata_e_c,
            })
            .then(() => {
              refetch()
              onCloseCheckMovimenti()
            })
        }}
        onOpen={onOpenCheckMovimenti}
      />
      <ModalCheckMovimenti
        isOpen={isOpenDataPagamento}
        onClose={() => {
          onCloseDataPagamento()
          setMovForDataPagamento(null)
        }}
        onToggle={onOpenDataPagamento}
        data_pagamento={dataPagamento}
        isCheckingMovimenti={isCheckingMovimenti}
        conto={contoBancario}
        progetto={progetto}
        only_data_pagamento
        onConfirm={async ({ data_pagamento }) => {
          await updateDataPagamento
            .mutateAsync({
              id: movForDataPagamento!,
              data_pagamento,
            })
            .then(() => {
              refetch()
              onCloseDataPagamento()
              setMovForDataPagamento(null)
            })
        }}
        onOpen={onOpenDataPagamento}
      />
    </Box>
  )
}
