import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import dayjs from 'dayjs'
import { orderBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import Paginator from '../../../components/Paginator'
import SelectAutoCompleteFilter from '../../../components/SelectAutocompleFilter'
import SubHeaderBlockPage from '../../../components/SubHeaderBlockPage'
import { OPTIONS_CAUSALI } from '../../../consts'
import { useQsFilters } from '../../../hooks/filters'
import {
  useCopiaMovimenti,
  useCreateSpesaPersonale,
  useDeleteMovimenti,
  useImportMovimentiSpeseLoco,
  useMovimenti,
  useMovimentiDownload,
  useMovimentiPdfDownload,
  useMovimentoPdfDownloadAll,
} from '../../../hooks/movimenti'
import { useProgettoSimple } from '../../../hooks/progetti'
import {
  useCanEditMovimenti,
  useCanEditMultipleMovimenti,
  useCanInsertMovimenti,
  useCanInsertMultipleMovimenti,
} from '../../../permissions'
import createStringLink from '../../../utils'
import MovimentiTable from '../../Movimenti/MovimentiTable'
import ModalSpesaPersonale from './ModalSpesaPersonale'
import { enGB, it, pt, es, fr } from 'date-fns/locale'
import { BiCaretDown, BiCaretUp } from 'react-icons/bi'
import ModalImportSpeseLoco from './ModalImportSpeseLoco'
import ModalDelete from '../../../components/ModalDelete'
import ModalCopiaMovimenti from './ModalCopiaMovimenti'
import { AccountUser } from '../../../types'
import { useAuthUser } from 'use-eazy-auth'

registerLocale('it', it)
registerLocale('en', enGB)
registerLocale('fr', fr)
registerLocale('es', es)
registerLocale('pt', pt)

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? '-progressivo',
  causale: params.get('causale') ?? '',
  valuta_locale: params.get('valuta_locale') ?? '',
  data_competenza_da: params.get('data_competenza_da') ?? '',
  data_competenza_a: params.get('data_competenza_a') ?? '',
  data_documento_da: params.get('data_documento_da') ?? '',
  data_documento_a: params.get('data_documento_a') ?? '',
  budget: params.get('budget') ?? '',
  has_allegato: params.get('has_allegato') ?? '',
  valori_cambio_mancanti: params.get('valori_cambio_mancanti') ?? '',
  bozza: params.get('bozza') ?? '',
  anticipo_chiuso: params.get('anticipo_chiuso') ?? '',
  duty_station: params.get('duty_station') ?? '',
  cambio_utilizzato: params.get('cambio_utilizzato') ?? '',
  progressivo_da: params.get('progressivo_da') ?? '',
  progressivo_a: params.get('progressivo_a') ?? '',
  progressivo_esatto: params.get('progressivo_esatto') ?? '',
  importo_rendicontazione_da: params.get('importo_rendicontazione_da') ?? '',
  importo_rendicontazione_a: params.get('importo_rendicontazione_a') ?? '',
})

export default function MovimentoProgettoList() {
  const { id } = useParams()
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)

  const filtersWithProgetto = useMemo(() => {
    return {
      ...filters,
      progetto: id,
    }
  }, [filters, id])

  const { data, isRefetching } = useMovimenti(filtersWithProgetto)
  const { t, i18n } = useTranslation()
  const { data: progetto } = useProgettoSimple(Number(id!))
  const { user } = useAuthUser<AccountUser>()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('movements'),
      },
    ]
  }, [t, progetto, id])

  const userCanInsertMovimenti = useCanInsertMovimenti(Number(id!))
  const userCanInsertMultipleMovimenti = useCanInsertMultipleMovimenti()
  const userCanEditMultipleMovimenti = useCanEditMultipleMovimenti()

  const downloadWithParams = useMovimentiDownload(filtersWithProgetto)

  const navigate = useNavigate()

  const optionsCausali = useMemo(() => {
    const options = OPTIONS_CAUSALI.map((v) => ({
      value: v.value,
      label: t(v.label),
    }))
    return orderBy(options, ['label'], ['asc'])
  }, [t])

  const optionsValuteLocali = useMemo(() => {
    return progetto?.valute_locali_data?.map((valuta) => ({
      value: valuta.id,
      label: valuta.codice,
    }))
  }, [progetto])

  const optionsYesOrNo = useMemo(() => {
    return [
      {
        value: 'true',
        label: t('yes'),
      },
      {
        value: 'false',
        label: t('no'),
      },
    ]
  }, [t])

  const {
    isOpen: isOpenCreateSpesaPersonale,
    onOpen: onOpenCreateSpesaPersonale,
    onClose: onCloseCreateSpesaPersonale,
    onToggle: onToggleCreateSpesaPersonale,
  } = useDisclosure()

  const {
    isOpen: isOpenImportSpeseLoco,
    onOpen: onOpenImportSpeseLoco,
    onClose: onCloseImportSpeseLoco,
    onToggle: onToggleImportSpeseLoco,
  } = useDisclosure()

  const createSpesaPersonale = useCreateSpesaPersonale(Number(id!))

  const [showFilters, setShowFilters] = useState(true)
  const [selectedAction, setSelectedAction] = useState(false)
  const [selectedActionMulti, setSelectedActionMulti] = useState(false)

  const areFiltersActive = useMemo(() => {
    if (
      filters.causale ||
      filters.valuta_locale ||
      filters.data_competenza_da ||
      filters.data_competenza_a ||
      filters.budget ||
      filters.has_allegato ||
      filters.bozza ||
      filters.anticipo_chiuso ||
      filters.valori_cambio_mancanti ||
      filters.data_documento_da ||
      filters.data_documento_a ||
      filters.duty_station ||
      filters.cambio_utilizzato ||
      filters.progressivo_da ||
      filters.progressivo_a ||
      filters.progressivo_esatto
    ) {
      return true
    }
    return false
  }, [filters])

  useEffect(() => {
    if (filters.causale !== 'anticipo-cassa') {
      setFilters({
        ...filters,
        anticipo_chiuso: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [movimentiForEdit, setMovimentiForEdit] = useState<number[]>([])

  const downloadMultiPdf = useMovimentiPdfDownload(movimentiForEdit)

  const downloadAllPdf = useMovimentoPdfDownloadAll(filtersWithProgetto)

  const importSpeseLoco = useImportMovimentiSpeseLoco()

  const [isImporting, setIsImporting] = useState(false)

  const [errors, setErrors] = useState(null)

  const {
    isOpen: isOpenDelete,
    onClose: onCloseDelete,
    onOpen: onOpenDelete,
    onToggle: onToggleDelete,
  } = useDisclosure()

  const deleteMovimenti = useDeleteMovimenti()

  const {
    isOpen: isOpenCopiaMovimento,
    onOpen: onOpenCopiaMovimento,
    onClose: onCloseCopiaMovimento,
    onToggle: onToggleCopiaMovimento,
  } = useDisclosure()

  const copiaMovimenti = useCopiaMovimenti()

  const toast = useToast()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box zIndex={1000} display={'flex'} alignItems={'center'}>
              <Box position={'relative'}>
                <Button
                  size={'sm'}
                  me={2}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                    opacity: 0.8,
                  }}
                  onClick={() => {
                    // open in new tab the pdf
                    downloadAllPdf()
                  }}
                  bg={'brandLight'}
                  color={'brand'}
                >
                  {t('pdf_list')}
                </Button>

                <Button
                  size={'sm'}
                  bg={'brandLight'}
                  color={'brand'}
                  me={2}
                  _hover={{
                    bg: 'brandSecondary',
                    color: 'white',
                  }}
                  onClick={() => {
                    setShowFilters(!showFilters)
                  }}
                >
                  {showFilters ? t('hide_filters') : t('show_filters')}
                </Button>
                {areFiltersActive && (
                  <Box
                    width={3}
                    height={3}
                    borderRadius={'50%'}
                    position={'absolute'}
                    top={-1}
                    right={1}
                    bg={'brand'}
                  ></Box>
                )}
              </Box>
              <Box position={'relative'}>
                {movimentiForEdit.length > 0 && (
                  <Button
                    size={'sm'}
                    me={2}
                    color={'brand'}
                    border={'1px solid'}
                    borderColor={'brand'}
                    _hover={{ bg: 'brand', color: 'white' }}
                    bg={'white'}
                    rightIcon={
                      selectedActionMulti ? <BiCaretUp /> : <BiCaretDown />
                    }
                    onClick={() => {
                      setSelectedActionMulti(!selectedActionMulti)
                      setSelectedAction(false)
                    }}
                  >
                    {t('select_action_multi')}
                  </Button>
                )}
                {selectedActionMulti && movimentiForEdit.length > 0 && (
                  <Box
                    position={'absolute'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    top={'30px'}
                    right={'0px'}
                    mt={2}
                    p={2}
                    border={'1px solid'}
                    borderColor={'brandLight'}
                    boxShadow={'0px 4px 6px -1px rgba(0, 0, 0, 0.1)'}
                    bg={'white'}
                    borderRadius={'md'}
                    zIndex={1000}
                  >
                    {userCanInsertMovimenti && (
                      <Button
                        size={'sm'}
                        color={'darkblue'}
                        width={'100%'}
                        display={'flex'}
                        justifyContent={'flex-start'}
                        fontWeight={500}
                        _hover={{ bg: 'brand', color: 'white' }}
                        onClick={() => {
                          onOpenCopiaMovimento()
                        }}
                      >
                        {t('copy_movements')}
                      </Button>
                    )}
                    {userCanEditMultipleMovimenti &&
                      movimentiForEdit.length > 0 && (
                        <Button
                          size={'sm'}
                          mt={2}
                          width={'100%'}
                          display={'flex'}
                          justifyContent={'flex-start'}
                          color={'brandBlue'}
                          bg={'card'}
                          _hover={{
                            bg: 'brandBlue',
                            color: 'white',
                          }}
                          onClick={() => {
                            navigate(
                              '/' +
                                i18n.language +
                                '/projects/' +
                                id +
                                '/movements/edit-multiple?' +
                                movimentiForEdit
                                  .map((movimento) => 'movimenti=' + movimento)
                                  .join('&')
                            )
                          }}
                        >
                          {t('edit_multiple')}
                        </Button>
                      )}
                    {userCanEditMultipleMovimenti &&
                     ((progetto?.data_blocco_compentenza || progetto?.data_blocco_finale || progetto?.inserimento_bloccato) && user?.is_staff) &&
                      movimentiForEdit.length > 0 && (
                        <Button
                          size={'sm'}
                          mt={2}
                          width={'100%'}
                          display={'flex'}
                          justifyContent={'flex-start'}
                          color={'brandBlue'}
                          bg={'card'}
                          _hover={{
                            bg: 'brandBlue',
                            color: 'white',
                          }}
                          onClick={() => {
                            onOpenDelete()
                          }}
                        >
                          {t('delete_movements')}
                        </Button>
                      )}
                    <Button
                      size={'sm'}
                      mt={2}
                      width={'100%'}
                      display={'flex'}
                      justifyContent={'flex-start'}
                      color={'brandBlue'}
                      bg={'card'}
                      _hover={{
                        bg: 'brandBlue',
                        color: 'white',
                      }}
                      onClick={() => {
                        // open in new tab the pdf
                        downloadMultiPdf()
                      }}
                    >
                      {t('download_pdf_multiple')}
                    </Button>
                  </Box>
                )}
              </Box>
              {userCanInsertMovimenti && (
                <>
                  <Button
                    size={'sm'}
                    color={'white'}
                    _hover={{ bg: 'brand' }}
                    bg={'brandSecondary'}
                    rightIcon={selectedAction ? <BiCaretUp /> : <BiCaretDown />}
                    onClick={() => {
                      setSelectedAction(!selectedAction)
                      setSelectedActionMulti(false)
                    }}
                  >
                    {t('select_action')}
                  </Button>
                  {selectedAction && (
                    <Box
                      position={'absolute'}
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'flex-start'}
                      top={'40px'}
                      right={'32px'}
                      mt={2}
                      p={2}
                      border={'1px solid'}
                      borderColor={'brandLight'}
                      boxShadow={'0px 4px 6px -1px rgba(0, 0, 0, 0.1)'}
                      bg={'white'}
                      // bg={'brandLight'}
                      borderRadius={'md'}
                      zIndex={1000}
                    >
                      {userCanInsertMovimenti && (
                        <Button
                          size={'sm'}
                          color={'darkblue'}
                          width={'100%'}
                          fontWeight={500}
                          display={'flex'}
                          justifyContent={'flex-start'}
                          _hover={{ bg: 'brand', color: 'white' }}
                          bg={'background'}
                          onClick={() => {
                            navigate(
                              createStringLink({
                                path: `/projects/${id}/movements/create`,
                                lang: i18n.language,
                              })
                            )
                          }}
                        >
                          {t('create')}
                        </Button>
                      )}
                      {userCanInsertMultipleMovimenti && (
                        <Button
                          size={'sm'}
                          mt={2}
                          color={'darkblue'}
                          width={'100%'}
                          fontWeight={500}
                          display={'flex'}
                          justifyContent={'flex-start'}
                          _hover={{ bg: 'brand', color: 'white' }}
                          bg={'background'}
                          onClick={() => {
                            navigate(
                              createStringLink({
                                path: `/projects/${id}/movements/create-multiple`,
                                lang: i18n.language,
                              })
                            )
                          }}
                        >
                          {t('create_multiple')}
                        </Button>
                      )}
                      <Button
                        mt={2}
                        size={'sm'}
                        color={'darkblue'}
                        width={'100%'}
                        display={'flex'}
                        justifyContent={'flex-start'}
                        fontWeight={500}
                        _hover={{ bg: 'brand', color: 'white' }}
                        onClick={() => {
                          navigate(
                            createStringLink({
                              path: `/projects/${id}/movements/create?bozza=true`,
                              lang: i18n.language,
                            })
                          )
                        }}
                      >
                        {t('create_draft')}
                      </Button>
                      {userCanInsertMovimenti && (
                        <Button
                          mt={2}
                          size={'sm'}
                          color={'darkblue'}
                          width={'100%'}
                          display={'flex'}
                          justifyContent={'flex-start'}
                          fontWeight={500}
                          _hover={{ bg: 'brand', color: 'white' }}
                          onClick={() => {
                            onOpenImportSpeseLoco()
                            setSelectedAction(false)
                          }}
                        >
                          {t('import_spese_loco')}
                        </Button>
                      )}
                    </Box>
                  )}
                </>
              )}
            </Box>
          }
        />
        {showFilters && (
          <Card
            px={2}
            py={0}
            // py={2}
            mt={1}
            zIndex={100}
            mb={2}
            boxShadow={
              '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
            }
          >
            <CardBody py={3}>
              <Grid templateColumns={'repeat(6, 1fr)'} gap={6}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('causale')}</FormLabel>
                    <Select
                      options={optionsCausali ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        setFilters({
                          ...filters,
                          causale: values?.value ?? '',
                          page: 1,
                        })
                      }}
                      value={
                        optionsCausali?.find(
                          (v) => v.value === filters.causale
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('local_currency')}</FormLabel>
                    <Select
                      options={optionsValuteLocali ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        if (values === undefined || values === null) {
                          setFilters({
                            ...filters,
                            valuta_locale: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            valuta_locale: String(values?.value) ?? '',
                            page: 1,
                          })
                        }
                      }}
                      value={
                        optionsValuteLocali?.find(
                          (v) => String(v.value) === filters.valuta_locale
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('date_competence_from')}
                    </FormLabel>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      onChange={(date) => {
                        if (date) {
                          setFilters({
                            ...filters,
                            data_competenza_da:
                              dayjs(date).format('YYYY-MM-DD'),
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            data_competenza_da: '',
                            page: 1,
                          })
                        }
                      }}
                      dateFormat={'dd/MM/yyyy'}
                      clearButtonClassName={'clear-button'}
                      selected={
                        filters.data_competenza_da
                          ? dayjs(filters.data_competenza_da).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('date_competence_to')}
                    </FormLabel>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      onChange={(date) => {
                        if (date) {
                          setFilters({
                            ...filters,
                            data_competenza_a: dayjs(date).format('YYYY-MM-DD'),
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            data_competenza_a: '',
                            page: 1,
                          })
                        }
                      }}
                      dateFormat={'dd/MM/yyyy'}
                      clearButtonClassName={'clear-button'}
                      selected={
                        filters.data_competenza_a
                          ? dayjs(filters.data_competenza_a).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('budget')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.budget}
                      url={'/api/budgets/options/'}
                      params={{
                        progetto: id,
                      }}
                      isMulti={false}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            budget: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            budget: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('exchange_rate_used')}
                    </FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.cambio_utilizzato}
                      url={`/api/progetti/${id}/options-cambi-utilizzati`}
                      isMulti={false}
                      params={{
                        da_data: filters.data_competenza_da,
                        a_data: filters.data_competenza_a,
                      }}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            cambio_utilizzato: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            cambio_utilizzato: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
              <Grid templateColumns={'repeat(6, 1fr)'} gap={6} mt={2}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('duty_station')}</FormLabel>
                    <SelectAutoCompleteFilter
                      value={filters.duty_station}
                      url={'/api/duty-stations/options/'}
                      isMulti={false}
                      onChange={(value: any) => {
                        if (!value) {
                          setFilters({
                            ...filters,
                            duty_station: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            duty_station: value.value,
                            page: 1,
                          })
                        }
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('has_attachment')}</FormLabel>
                    <Select
                      options={optionsYesOrNo ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        if (!values) {
                          setFilters({
                            ...filters,
                            has_allegato: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            has_allegato: String(values?.value) ?? '',
                            page: 1,
                          })
                        }
                      }}
                      value={
                        optionsYesOrNo?.find(
                          (v) => String(v.value) === filters.has_allegato
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('draft')}</FormLabel>
                    <Select
                      options={optionsYesOrNo ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        if (!values) {
                          setFilters({
                            ...filters,
                            bozza: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            bozza: String(values?.value) ?? '',
                            page: 1,
                          })
                        }
                      }}
                      value={
                        optionsYesOrNo?.find(
                          (v) => String(v.value) === filters.bozza
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('missing_exchange_rate')}
                    </FormLabel>
                    <Select
                      options={optionsYesOrNo ?? []}
                      isSearchable
                      isClearable
                      size={'sm'}
                      selectedOptionColorScheme={'orange'}
                      focusBorderColor="brandSecondary"
                      placeholder={''}
                      onChange={(values) => {
                        if (!values) {
                          setFilters({
                            ...filters,
                            valori_cambio_mancanti: '',
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            valori_cambio_mancanti: String(values?.value) ?? '',
                            page: 1,
                          })
                        }
                      }}
                      value={
                        optionsYesOrNo?.find(
                          (v) =>
                            String(v.value) === filters.valori_cambio_mancanti
                        ) ?? null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('date_document_from')}
                    </FormLabel>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      onChange={(date) => {
                        if (date) {
                          setFilters({
                            ...filters,
                            data_documento_da: dayjs(date).format('YYYY-MM-DD'),
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            data_documento_da: '',
                            page: 1,
                          })
                        }
                      }}
                      dateFormat={'dd/MM/yyyy'}
                      clearButtonClassName={'clear-button'}
                      selected={
                        filters.data_documento_da
                          ? dayjs(filters.data_documento_da).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('date_document_to')}</FormLabel>
                    <ReactDatePicker
                      showIcon
                      locale={i18n.language}
                      isClearable
                      onChange={(date) => {
                        if (date) {
                          setFilters({
                            ...filters,
                            data_documento_a: dayjs(date).format('YYYY-MM-DD'),
                            page: 1,
                          })
                        } else {
                          setFilters({
                            ...filters,
                            data_documento_a: '',
                            page: 1,
                          })
                        }
                      }}
                      dateFormat={'dd/MM/yyyy'}
                      clearButtonClassName={'clear-button'}
                      selected={
                        filters.data_documento_a
                          ? dayjs(filters.data_documento_a).toDate()
                          : null
                      }
                    />
                  </FormControl>
                </GridItem>
              </Grid>
              <Grid templateColumns={'repeat(6, 1fr)'} gap={6} mt={2}>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('progressive_from')}</FormLabel>
                    <Input
                      type="number"
                      value={filters.progressivo_da}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          progressivo_da: e.target.value,
                          page: 1,
                        })
                      }}
                      className="form-control"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>{t('progressive_to')}</FormLabel>
                    <Input
                      type="number"
                      value={filters.progressivo_a}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          progressivo_a: e.target.value,
                          page: 1,
                        })
                      }}
                      className="form-control"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('exact_progressive')}
                    </FormLabel>
                    <Input
                      type="number"
                      value={filters.progressivo_esatto}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          progressivo_esatto: e.target.value,
                          page: 1,
                        })
                      }}
                      className="form-control"
                    />
                  </FormControl>
                </GridItem>
                {filters.causale === 'anticipo-cassa' && (
                  <GridItem colSpan={1} mt={0}>
                    <FormControl>
                      <FormLabel fontSize={13}>
                        {t('anticipo_chiuso')}
                      </FormLabel>
                      <Select
                        options={optionsYesOrNo ?? []}
                        isSearchable
                        isClearable
                        size={'sm'}
                        selectedOptionColorScheme={'orange'}
                        focusBorderColor="brandSecondary"
                        placeholder={''}
                        onChange={(values) => {
                          if (!values) {
                            setFilters({
                              ...filters,
                              anticipo_chiuso: '',
                              page: 1,
                            })
                          } else {
                            setFilters({
                              ...filters,
                              anticipo_chiuso: String(values?.value) ?? '',
                              page: 1,
                            })
                          }
                        }}
                        value={
                          optionsYesOrNo?.find(
                            (v) => String(v.value) === filters.anticipo_chiuso
                          ) ?? null
                        }
                      />
                    </FormControl>
                  </GridItem>
                )}
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('amount_rendicontation_from')}
                    </FormLabel>
                    <Input
                      type="number"
                      value={filters.importo_rendicontazione_da}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          importo_rendicontazione_da: e.target.value,
                          page: 1,
                        })
                      }}
                      className="form-control"
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel fontSize={13}>
                      {t('amount_rendicontation_to')}
                    </FormLabel>
                    <Input
                      type="number"
                      value={filters.importo_rendicontazione_a}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          importo_rendicontazione_a: e.target.value,
                          page: 1,
                        })
                      }}
                      className="form-control"
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          </Card>
        )}
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'data_documento', label: t('date_document') },
              { value: 'data_competenza', label: t('date_competence') },
              { value: 'progressivo', label: t('progressive') },
              { value: 'causale', label: t('transaction_type') },
              { value: 'descrizione', label: t('description') },
              { value: 'importo', label: t('amount') },
              { value: 'valuta__codice', label: t('currency') },
              {
                value: 'importo_rendicontazione',
                label: t('amount_rendicontation'),
              },
            ]}
          />

          <MovimentiTable
            filtersOpen={showFilters}
            showProgetto={false}
            data={data}
            isRefetching={isRefetching}
            movimentiForEdit={movimentiForEdit}
            setMovimentiForEdit={setMovimentiForEdit}
          />
          <Box
            width={'100%'}
            mt={3}
            display={'flex'}
            justifyContent={'flex-end'}
          >
            <Paginator
              count={data!.count}
              currentPage={uiFilters.page}
              goToPage={(page) => setFilters({ page })}
            />
          </Box>
        </Flex>
      </Flex>
      <ModalSpesaPersonale
        isOpen={isOpenCreateSpesaPersonale}
        onClose={onCloseCreateSpesaPersonale}
        progetto={progetto}
        onOpen={onOpenCreateSpesaPersonale}
        onToggle={onToggleCreateSpesaPersonale}
        onConfirm={async (values: any) => {
          await createSpesaPersonale
            .mutateAsync({
              ...values,
              progetto: id,
            })
            .then(() => {
              onCloseCreateSpesaPersonale()
            })
        }}
      />
      {isOpenImportSpeseLoco && (
        <ModalImportSpeseLoco
          isOpen={isOpenImportSpeseLoco}
          isImporting={isImporting}
          onClose={() => {
            onCloseImportSpeseLoco()
          }}
          onOpen={onOpenImportSpeseLoco}
          progetto={progetto}
          errors={errors}
          onToggle={onToggleImportSpeseLoco}
          onConfirm={async (values) => {
            setIsImporting(true)
            await importSpeseLoco
              .mutateAsync({
                importFile: values.import_file,
              })
              .then((data: { num_rows: number }) => {
                setIsImporting(false)

                toast({
                  title: t('import_spese_loco'),
                  description:
                    t('you_have_imported') +
                    ' ' +
                    data.num_rows +
                    ' ' +
                    t('movements'),
                  status: 'success',
                  duration: 10000,
                  isClosable: true,
                })
                onCloseImportSpeseLoco()
              })
              .catch((err) => {
                setErrors(err.response.data)
              })
          }}
        />
      )}
      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        onConfirm={async () => {
          await deleteMovimenti
            .mutateAsync({
              ids: movimentiForEdit.map((movimento) => movimento),
            })
            .then(() => {
              onCloseDelete()
              toast({
                title: t('deleted'),
                description:
                  t('you_have_deleted') +
                  ' ' +
                  movimentiForEdit.length +
                  ' ' +
                  t('movements'),
                status: 'success',
                duration: 10000,
                isClosable: true,
              })
              setMovimentiForEdit([])
            })
        }}
        onOpen={onOpenDelete}
        onToggle={onToggleDelete}
        nameItem={movimentiForEdit.length + ' ' + t('movements')}
        title={t('delete') + ' ' + t('movements')}
      />
      {isOpenCopiaMovimento && (
        <ModalCopiaMovimenti
          isOpen={isOpenCopiaMovimento}
          onClose={onCloseCopiaMovimento}
          movimenti={movimentiForEdit}
          onConfirm={async (values) => {
            await copiaMovimenti
              .mutateAsync({
                ...values,
                progetto: id,
                ids: movimentiForEdit.map((movimento) => movimento),
              })
              .then(() => {
                onCloseCopiaMovimento()
                toast({
                  title: t('copied_movements'),
                  description:
                    t('you_have_copied') +
                    ' ' +
                    movimentiForEdit.length +
                    ' ' +
                    t('movements'),
                  status: 'success',
                  duration: 10000,
                  isClosable: true,
                })
                setMovimentiForEdit([])
              })
          }}
          onOpen={onOpenCopiaMovimento}
          onToggle={onToggleCopiaMovimento}
        />
      )}
    </Box>
  )
}
