import {
  Box,
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { InputFileField } from '../../../components/form'
import { Progetto } from '../../../types'
import { useState } from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  onToggle: () => void
  onConfirm: (value: any) => void
  progetto: Progetto | undefined
  errors?: string[] | null
  isImporting: boolean
}

export default function ModalImportSpeseLoco({
  progetto,
  isOpen,
  onClose,
  onConfirm,
  errors,
  isImporting,
}: Props) {
  const { t } = useTranslation()

  const initialValues = {
    import_file: null,
  }

  const ImportSpesaLocoSchema = Yup.object().shape({
    import_file: Yup.string().required(),
  })

  return (
    <Formik
      enableReinitialize
      onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
        onConfirm(values)
      }}
      validationSchema={ImportSpesaLocoSchema}
      initialValues={initialValues}
    >
      {({ handleSubmit, values, isSubmitting }) => (
        <Modal isCentered size={'xl'} isOpen={isOpen} onClose={onClose}>
          <form onSubmit={handleSubmit}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {t('import_spese_loco')} - {progetto?.codice}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Box
                  width={'100%'}
                  mt={2}
                  mb={4}
                >
                  <Button
                    bg={'brandBlue'}
                    _hover={{
                      opacity: 0.8,
                    }}
                    size={'sm'}
                    color={'white'}
                    as={Link}
                    href={'/modello_spese_loco.xlsx'}
                  >
                    {t('download_template')}{' '}
                  </Button>
                </Box>
                <Box>
                  <InputFileField
                    name={'import_file'}
                    label={t('import_file') ?? ''}
                    accept={'.xlsx'}
                    isRequired
                  />
                </Box>
                {errors && (
                  <Box
                    p={3}
                    color={'white'}
                    overflow={'auto'}
                    height={'max-content'}
                    mt={4}
                    bg={'red.400'}
                  >
                    {errors.map((e) => (
                      <Box>{e}</Box>
                    ))}
                  </Box>
                )}
              </ModalBody>

              <ModalFooter display={'flex'} justifyContent={'space-between'}>
                <Button
                  color={'brand'}
                  _hover={{
                    bg: 'brand',
                    color: 'white',
                  }}
                  bg={'brandLight'}
                  mr={3}
                  onClick={onClose}
                >
                  {t('close')}
                </Button>
                <Button
                  type="submit"
                  bg={'brand'}
                  isDisabled={!values.import_file || isImporting}
                  _hover={{
                    bg: 'brandSecondary',
                  }}
                  color={'white'}
                >
                  {isImporting && <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="white"
                    me={4}
                    size="sm"
                  />}
                  {t('importa')}
                </Button>
              </ModalFooter>
            </ModalContent>
          </form>
        </Modal>
      )}
    </Formik>
  )
}
