import {
  Box,
  Button,
  Checkbox,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import BreadCrumb from '../../../components/BreadCrumb'
import HeaderActionsPage from '../../../components/HeaderActionsPage'
import LangLinkWithStyle from '../../../components/LangLinkWithStyle'
import Paginator from '../../../components/Paginator'
import SubHeaderBlockPage from '../../../components/SubHeaderBlockPage'
import {
  useBudgets,
  useBudgetsDownload,
  useDeleteBudgets,
} from '../../../hooks/budgets'
import { useQsFilters } from '../../../hooks/filters'
import { useImportBudgets, useProgettoSimple } from '../../../hooks/progetti'
import { useCanCreateEditBudget } from '../../../permissions'
import createStringLink, { numberFormatter } from '../../../utils'
import ImportBudgetsModal from './ImportBudgetsModal'
import ModalDelete from '../../../components/ModalDelete'
import { AccountUser } from '../../../types'
import { useAuthUser } from 'use-eazy-auth'

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get('page') ?? 1),
  search: params.get('search') ?? '',
  ordering: params.get('ordering') ?? 'codice',
})

export default function BudgetsList() {
  const { id } = useParams()
  const { user } = useAuthUser<AccountUser>()
  const { filters, uiFilters, setFiltersDebounced, setFilters } =
    useQsFilters(initFilters)

  const [errors, setErrors] = useState(null)

  const navigate = useNavigate()

  const filtersWithProgetto = useMemo(() => {
    return {
      ...filters,
      progetto: id,
    }
  }, [filters, id])

  const {
    data,
    isLoading,
    refetch: refetchBudgets,
  } = useBudgets(filtersWithProgetto)
  const { t, i18n } = useTranslation()
  const { data: progetto } = useProgettoSimple(Number(id!))

  const importBudgets = useImportBudgets()

  const itemsBreadCrumb = useMemo(() => {
    return [
      {
        label: t('projects'),
        link: '/projects',
      },
      {
        label: progetto?.codice ?? '',
        link: `/projects/${id}`,
      },
      {
        label: t('budgets'),
      },
    ]
  }, [t, progetto, id])

  const downloadWithParams = useBudgetsDownload(filtersWithProgetto)
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure()

  const {
    isOpen: isOpenDelete,
    onClose: onCloseDelete,
    onOpen: onOpenDelete,
    onToggle: onToggleDelete,
  } = useDisclosure()

  const userCanCreateEditBudgets = useCanCreateEditBudget()

  const [budgetsForDelete, setBudgetsForDelete] = useState<number[]>([])

  const deleteBudgets = useDeleteBudgets()

  const toast = useToast()

  return (
    <Box width={'100%'} pt={67} background={'#F8F8F8'}>
      <Flex direction={'column'}>
        <HeaderActionsPage
          breadCrumbs={<BreadCrumb items={itemsBreadCrumb} />}
          blockRight={
            <Box display={'flex'} alignItems={'center'}>
              {userCanCreateEditBudgets && budgetsForDelete.length > 0 && (
                <Button
                  size={'sm'}
                  me={2}
                  _hover={{
                    bg: 'error',
                    color: 'white',
                    opacity: 0.8,
                  }}
                  onClick={onOpenDelete}
                  bg={'error'}
                  color={'white'}
                >
                  {t('delete_selected')}
                </Button>
              )}
              {userCanCreateEditBudgets && (
                <Button
                  size={'sm'}
                  border={'1px solid'}
                  borderColor={'brandBlue'}
                  color={'brandBlue'}
                  bg={'card'}
                  me={2}
                  _hover={{
                    bg: 'brandBlue',
                    color: 'white',
                  }}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        lang: i18n.language,
                        path: `/projects/${id}/budgets/edit-multiple`,
                      })
                    )
                  }}
                >
                  {t('edit_multiple')}
                </Button>
              )}
              {userCanCreateEditBudgets && (
                <Button
                  size={'sm'}
                  color={'white'}
                  _hover={{ bg: 'brand' }}
                  bg={'brandSecondary'}
                  onClick={() => {
                    navigate(
                      createStringLink({
                        lang: i18n.language,
                        path: `/projects/${id}/budgets/create`,
                      })
                    )
                  }}
                >
                  {t('create')}
                </Button>
              )}
            </Box>
          }
        />
        <Flex direction={'column'} align={'self-start'} width={'100%'}>
          <SubHeaderBlockPage
            setFiltersDebounced={setFiltersDebounced}
            setFilters={setFilters}
            downloadWithParams={downloadWithParams}
            importData={userCanCreateEditBudgets ? onOpen : undefined}
            uiFilters={uiFilters}
            data={data}
            filters={filters}
            options={[
              { value: 'codice', label: t('code') },
              {
                value: 'descrizione',
                label: t('description'),
              },
              {
                value: 'importo',
                label: t('import'),
              },
              {
                value: 'annualita',
                label: t('annuity'),
              },
              {
                value: 'codice_piano_conti',
                label: t('account_plan_code'),
              },
              {
                value: 'codice_paese',
                label: t('country_code'),
              },
              {
                value: 'importo_aggiornato',
                label: t('updated_amount'),
              },
            ]}
          />
          {isLoading ? (
            <Flex align={'center'} justify="center">
              <Spinner color="orange" />
            </Flex>
          ) : (
            <>
              <Box
                width={'100%'}
                height={'calc(100vh - 240px)'}
                mt={3}
                overflowY={'auto'}
                boxShadow={
                  '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)'
                }
              >
                <Table variant={'pinc'}>
                  <Thead position={'sticky'} top={0}>
                    <Tr>
                      <Th>
                        <Checkbox
                          width={5}
                          height={5}
                          size={'md'}
                          colorScheme={'orange'}
                          borderColor={'brandLight'}
                          isChecked={
                            data!.results.length === budgetsForDelete.length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              setBudgetsForDelete(
                                data!.results.map((budget) => budget.id)
                              )
                            } else {
                              setBudgetsForDelete([])
                            }
                          }}
                        />
                      </Th>
                      <Th>{t('name')}</Th>
                      <Th>{t('description')}</Th>
                      <Th>{t('import')}</Th>
                      <Th>{t('annuity')}</Th>
                      <Th>{t('country_code')}</Th>
                      <Th>{t('account_plan_code')}</Th>
                      <Th>{t('updated_amount')}</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data!.results.map((budget) => (
                      <Tr padding={2} key={budget.id}>
                        {userCanCreateEditBudgets && (
                          <Td>
                            <Checkbox
                              width={5}
                              disabled={budget.can_delete === false && !user?.is_staff}
                              height={5}
                              size={'md'}
                              colorScheme={'orange'}
                              borderColor={'brandLight'}
                              isChecked={
                                budgetsForDelete.includes(budget.id) || false
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setBudgetsForDelete([
                                    ...budgetsForDelete,
                                    budget.id,
                                  ])
                                } else {
                                  setBudgetsForDelete(
                                    budgetsForDelete.filter(
                                      (id) => id !== budget.id
                                    )
                                  )
                                }
                              }}
                            />
                          </Td>
                        )}
                        <Td>
                          <LangLinkWithStyle
                            to={`/projects/${id}/budgets/${budget.id}`}
                          >
                            {budget.codice}
                          </LangLinkWithStyle>
                        </Td>
                        <Td>{budget.descrizione}</Td>
                        <Td textAlign={'right'}>
                          <Box color={'brand'} fontWeight={700}>
                            {numberFormatter.format(budget.importo ?? 0)}
                          </Box>
                          {progetto?.valuta_rendicontazione_data?.codice}
                        </Td>
                        <Td>{budget.annualita}</Td>
                        <Td>{budget.codice_paese}</Td>
                        <Td>{budget.codice_piano_conti}</Td>
                        <Td textAlign={'right'}>
                          <Box color={'brand'} fontWeight={700}>
                            {numberFormatter.format(
                              budget.importo_aggiornato ?? 0
                            )}
                          </Box>
                          {progetto?.valuta_rendicontazione_data?.codice}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
              <Box
                width={'100%'}
                mt={3}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Paginator
                  count={data!.count}
                  currentPage={uiFilters.page}
                  goToPage={(page) => setFilters({ page })}
                />
              </Box>
            </>
          )}
        </Flex>
      </Flex>
      {isOpen && (
        <ImportBudgetsModal
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          progetto={progetto}
          errors={errors}
          onToggle={onToggle}
          onConfirm={async (values) => {
            await importBudgets
              .mutateAsync({
                progetto: Number(id!),
                importFile: values.import_file,
              })
              .then(() => {
                onClose()
                refetchBudgets()
              })
              .catch((err) => {
                setErrors(err.response.data.error)
              })
          }}
        />
      )}
      <ModalDelete
        isOpen={isOpenDelete}
        onClose={onCloseDelete}
        onConfirm={async () => {
          await deleteBudgets
            .mutateAsync({
              ids: budgetsForDelete,
            })
            .then(() => {
              onCloseDelete()
              toast({
                title: t('deleted'),
                description:
                  t('you_have_deleted') +
                  ' ' +
                  budgetsForDelete.length +
                  ' ' +
                  t('budgets'),
                status: 'success',
                duration: 10000,
                isClosable: true,
              })
              setBudgetsForDelete([])
            })
        }}
        onOpen={onOpenDelete}
        onToggle={onToggleDelete}
        nameItem={budgetsForDelete.length + ' ' + t('budgets')}
        title={t('delete') + ' ' + t('budgets')}
      />
    </Box>
  )
}
